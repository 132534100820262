const mixin = {
  data() {
    return {
      pageShow: false 
    }
  },
  methods: {
    showToast(text,duration = 0) {
      this.$toast.loading({
        duration: duration,
        message: text,
        forbidClick: true,
        loadingType: 'spinner'
      })
    },
    clearToast() {
      this.pageShow = true
      this.$toast.clear()
    }
  }
}

export default mixin
