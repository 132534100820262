<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {}
  },
  methods: {}
}
</script>


<style>
@import url('./common/shortcut.css');
.van-toast__icon {
  font-size: 3rem !important;
}
.van-dialog__footer--buttons .van-button {
  font-size: 1.6rem !important;
  font-weight: 700;
}
.van-toast--html .van-toast__text, .van-toast--text .van-toast__text {
  line-height: 1.8rem;
}
.van-hairline--top::after,[class*=van-hairline]::after {
  border: 0 !important;
}
.van-dialog {
  width: 30rem !important;
  /* height: 19rem !important; */
  padding-top: 2rem;
  font-size: 1.8rem !important;
  border-radius: 1rem !important;
}
.van-dialog__content {
  height: 10rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f2f2f2;
}
.van-dialog__message--has-title {
  padding: 2rem 1rem 3rem 1rem !important;
  font-size: 1.6rem !important;
}
.van-dialog__message {
  max-height: auto !important;
  line-height: unset !important;
  padding: 0.8 !important;
}
.van-dialog__header {
  font-weight: 700 !important;
  padding-top: 0 !important;
  line-height: unset !important;
}
.van-button,.van-button--large,.van-dialog__cancel, .van-dialog__confirm {
  height: auto !important;
  line-height: unset !important;
}
.van-dialog__footer--buttons {
  height: auto;
}
.van-dialog__cancel, .van-dialog__confirm {
  font-size: 2rem !important;
}
.van-button--default {
  border-right: 0.1rem solid #dadada !important;
}
.van-dialog__footer {
  height: 4.5rem;
  border-top: 0.1rem solid #dadada !important;
}
.van-toast {
  width: 11rem;
  height: 11rem;
  /* padding: 1rem; */
  border-radius: 0.5rem;
  font-size: 1.4rem;
  line-height: 2rem;
}
.van-toast__text {
  margin-top: 1rem;
  font-size: 1.4rem;
}
.van-loading__spinner {
  width: 3rem;
  height: 3rem;
}
.van-toast--html, .van-toast--text {
  padding: 2rem 2rem;
  border-radius: 0.5rem;
  font-size: 1.4rem;
  white-space: nowrap;
}
body {
  margin: 0;
  background-color: #fff;
  padding: 0;
}
html {
  width: 100%;
  font-size: 20px;
  color: #333;
  margin: 0;
  padding: 0;
  font-family: PingFangSC-Regular, sans-serif;
}

html,
body,
header,
section,
footer,
div,
ul,
ol,
li,
img,
a,
span,
em,
del,
legend,
center,
strong,
var,
fieldset,
form,
label,
dl,
dt,
dd,
cite,
input,
hr,
time,
mark,
code,
figcaption,
figure,
textarea,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  border: 0;
  padding: 0;
  font-style: normal;
}
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none; /*系统默认菜单被禁用*/
  -webkit-user-select: none; /*webkit浏览器*/
  -khtml-user-select: none; /*早期浏览器*/
  -moz-user-select: none; /*火狐*/
  -ms-user-select: none; /*IE10*/
  user-select: none;
}
*::-webkit-scrollbar {
  display: none;
}
input,
textarea {
  -webkit-user-select: auto; /*webkit浏览器*/
  outline: none;
  background: none;
  border: 0px;
}
li {
  text-decoration: none;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
.popup_box {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 999;
  background: rgba(74, 74, 74, 0.6);
}

.popup_box::after {
  content: '';
  display: inline-block;
  height: 100%;
  width: 0;
  vertical-align: middle;
}

.popup_cont {
  padding: 1rem;
  background-color: #fff;
  border-radius: 0.5rem;
  display: inline-block;
  vertical-align: middle;
  margin-top: -5rem;
}

.fadeInDown {
  animation: 0.5s fadeInDown forwards;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.fadeInUp {
  animation: 0.5s fadeInUp forwards;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 50%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.fadeIn {
  animation: 0.8s fadeIn forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeOutDown {
  animation: 0.5s fadeOutDown forwards;
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}
/* 文字省略 */
.ellip-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}
.ellip-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.ellip-3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
.ellip-4 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}
/*缺省页样式*/
.nothing-box {
  padding-top: 30vh;
  height: 100vh;
  background-color: #fff;
  text-align: center;
}
.nothing-box img {
  width: 187px;
  height: 144px;
}
.nothing-box p {
  margin-top: 15px;
  color: #B0B0B0;
  font-size: 15px;
  letter-spacing: 0.5rem;
}
</style>
