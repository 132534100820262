import Vue from 'vue';
import 
  {
    Tabbar, 
    TabbarItem, 
    Search, 
    Icon, 
    NavBar, 
    Button,
    Divider,
    Toast,
    Overlay,
    Popup,
    ActionSheet,
    Dialog,
    Slider,
    Picker,
    tabs,
    tab, 
  } from 'vant'

Vue.use(Icon);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Search);
Vue.use(NavBar);
Vue.use(Button);
Vue.use(Divider);
Vue.use(Toast);
Vue.use(Overlay);
Vue.use(Popup);
Vue.use(ActionSheet);
Vue.use(Dialog);
Vue.use(Slider);
Vue.use(Picker);
Vue.use(tabs);
Vue.use(tab);
