import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "index",
    meta: {
      "keepAlive": true,
      title: "逗哥写作神器"
    },
    component: () => import(/* webpackChunkName: "index" */ "../views/index.vue")
  },{
    path: "/moreVoice",
    name: "moreVoice",
    meta: {
      "keepAlive": false,
      title: "逗哥写作神器"
    },
    component: () => import(/* webpackChunkName: "index" */ "../views/moreVoice.vue")
  },{
    path: "/similarWord",
    name: "similarWord",
    meta: {
      "keepAlive": false,
      title: "逗哥写作神器"
    },
    component: () => import(/* webpackChunkName: "index" */ "../views/similarWord.vue")
  },{
    path: "/badWordCkeck",
    name: "badWordCkeck",
    meta: {
      "keepAlive": false,
      title: "逗哥写作神器"
    },
    component: () => import(/* webpackChunkName: "index" */ "../views/badWordCkeck.vue")
  },{
    path: "/historyVersions",
    name: "historyVersions",
    meta: {
      "keepAlive": false,
      title: "逗哥写作神器"
    },
    component: () => import(/* webpackChunkName: "index" */ "../views/historyVersions.vue")
  }
]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
