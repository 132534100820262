import Vue from 'vue'
import App from './App.vue'
import router from './router'
import mixin from "@/mixins/mixin"
import '@/utils/rem/rem'  // 开启rem适配
// import Vconsolefrom from 'vconsole' // 引入vonsole 方便手机调试
import {Row, Col, Toast} from 'vant'
import './vant/vant.js'
import './assets/font/iconfont.css'

//全局混入方法
Vue.mixin(mixin)

// 全局使用组件
Vue.use(Toast);
Vue.use(router);
Vue.use(Row).use(Col)
Vue.config.productionTip = false

// const vConsole = new Vconsolefrom()
// Vue.use(vConsole);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
